<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <p>Basic Info</p>

        <translatable-input
          v-model="form.channel.name"
          label="A easy recognizable Name."
          outlined
          :rules="[required]"
        ></translatable-input>

        <v-text-field
          v-model="form.channel.code"
          label="Channel Code"
          placeholder="PWC"
          outlined
          :rules="[required]"
        ></v-text-field>

        <v-divider class="mt-b" />

        <p>Select a seed white-label channel to start from. </p>
        <p>It reuse most of its attribute for the ease of setup.</p>

        <v-select
          v-model="form.reference_channel_id"
          label="Copy From Channel"
          :items="channelOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <v-divider class="mt-4" />

        <p class="mt-4">
          Webhook and service info
        </p>

        <v-text-field
          v-model="form.webhook.endpoint"
          label="Webhook Endpoint"
          placeholder="https://api.portal.mixcarehealth.com/webhooks/core"
          outlined
          :rules="[required]"
        ></v-text-field>

        <v-divider class="mt-4" />

        <v-switch
          v-model="different.stripe"
          label="Prepare a new stripe account for business with different domain."
        />

        <template v-if="different.stripe">
          <v-text-field
            v-model="stripeCredentials['services.stripe.key']"
            label="Stripe Public Key"
            placeholder="pk_prod_123......"
            outlined
            :rules="[required]"
          ></v-text-field>

          <v-text-field
            v-model="stripeCredentials['services.stripe.secret']"
            label="Stripe Secret Key"
            placeholder="sk_prod_456......"
            outlined
            :rules="[required]"
          ></v-text-field>

          <v-text-field
            v-model="stripeCredentials['services.stripe.webhook_secret']"
            label="Stripe Webhook Secret"
            placeholder="wh_prod_789......"
            outlined
            :rules="[required]"
          ></v-text-field>

          <v-divider class="mt-4" />
        </template>

        <v-switch
          v-model="different.mail"
          label="This channel send with different mail settings"
        />

        <template v-if="different.mail">
          <v-select
            v-model="mailCredentials['mail.default']"
            label="Default Mailer"
            :items="mailerOptions"
            item-text="title"
            item-value="value"
            outlined
          ></v-select>
          <!-- <v-text-field
            v-model="mailCredentials['mail.default']"
            label=""
            placeholder="e.g......."
            outlined
          ></v-text-field> -->

          <template v-if="mailCredentials['mail.default'] === 'smtp'">
            <p>Credentials for SMTP:</p>
            <v-text-field
              v-model="mailCredentials['mail.mailers.smtp.host']"
              label="Mail Host"
              placeholder="e.g: 127.0.0.1"
              outlined
            ></v-text-field>

            <v-text-field
              v-model="mailCredentials['mail.mailers.smtp.port']"
              label="Mail Port"
              placeholder="e.g: 1025"
              outlined
            ></v-text-field>

            <v-text-field
              v-model="mailCredentials['mail.mailers.smtp.username']"
              label="Mail Username"
              placeholder=""
              outlined
            ></v-text-field>

            <v-text-field
              v-model="mailCredentials['mail.mailers.smtp.password']"
              label="Mail password"
              placeholder=""
              outlined
            ></v-text-field>

            <v-text-field
              v-model="mailCredentials['mail.mailers.smtp.encryption']"
              label="Mail encryption"
              placeholder="e.g. tls or leave empty"
              outlined
            ></v-text-field>
          </template>
        </template>

        <template v-if="mailCredentials['mail.default'] === 'ses'">
          <p>Credentials for SES Mailer (AWS):</p>
          <v-text-field
            v-model="mailCredentials['services.ses.key']"
            label="AWS ACCESS KEY ID"
            placeholder="e.g: AKIAS*********7HO"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="mailCredentials['services.ses.secret']"
            label="AWS SECRET"
            placeholder="e.g: 6li95B5g+LcN************T9pPkLUgqss"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="mailCredentials['services.ses.region']"
            label="AWS SES REGION (US only)"
            placeholder="e.g: us-east-1"
            outlined
          ></v-text-field>
        </template>

        <v-divider class="mt-4" />

        <p>General Mail Settings:</p>

        <v-text-field
          v-model="mailCredentials['mail.from.address']"
          label="Mail From Address"
          placeholder="e.g. no-reply@mixcarehealth.com"
          outlined
        ></v-text-field>

        <v-text-field
          v-model="mailCredentials['mail.from.name']"
          label="Mail From Name"
          placeholder="e.g. MixCare Health"
          outlined
        ></v-text-field>

        <v-text-field
          v-model="mailCredentials['mail.bcc']"
          label="Mail BCC for all mails"
          placeholder="e.g. mixcare.booking@gmail.com"
          outlined
        ></v-text-field>

        <v-divider class="mt-4" />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import channelStore from '@/modules/channel/store'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { computed, reactive, ref } from '@vue/composition-api'

export default {
  components: { TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      credentials: [],
      webhook: {
        endpoint: '',
      },
      channel: {
        name: useTranslatable(),
        code: 'MIX',
      },
      reference_channel_id: null,
    }

    const stripeCredentials = reactive({
      'services.stripe.key': '',
      'services.stripe.secret': '',
      'services.stripe.webhook_secret': '',
    })
    const mailCredentials = reactive({
      'mail.default': 'smtp',
      'mail.from.address': 'no-reply@mixcarehealth.com',
      'mail.from.name': 'MixCare Health',
      'mail.bcc': 'mixcare.booking@gmail.com',
      'mail.mailers.smtp.host': '127.0.0.1',
      'mail.mailers.smtp.port': '1025',
      'mail.mailers.smtp.username': '',
      'mail.mailers.smtp.password': '',
      'mail.mailers.smtp.encryption': 'tls',
      'services.ses.key': '',
      'services.ses.secret': '',
      'services.ses.region': '',
    })

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const different = reactive({
      stripe: true,
      mail: false,
    })

    const channelOptions = computed(() => channelStore.state.channels.map(ch => ({ title: `${t(ch.name)} (${ch.code})`, value: ch.id })))

    const validate = () => {
      if (formElem.value.validate()) {
        form.value.credentials = []

        if (different.stripe) {
          for (const [key, value] of Object.entries(stripeCredentials)) {
            form.value.credentials.push({
              key,
              value,
            })
          }
        }

        if (different.mail) {
          for (const [key, value] of Object.entries(mailCredentials)) {
            form.value.credentials.push({
              key,
              value,
            })
          }
        }
        emit('submit', form.value)
      }
    }

    const mailerOptions = [
      { title: 'SMTP', value: 'smtp' },
      { title: 'SES (AWS)', value: 'ses' },
    ]

    return {
      stripeCredentials,
      mailCredentials,
      form,
      formElem,
      different,
      channelOptions,

      validate,
      required,
      mailerOptions,
    }
  },
}
</script>